import React, { useCallback } from 'react';
import { Button, Control, Form, HelgaContainer, HelgaContainerProps, HelgaImageType, List, Tabs, Tooltip } from 'components';
import { Input } from 'antd';
import { useBasketContext, useRequirementFilteredForms, useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';
import { Translate, useIntl } from 'providers';
import { Color, Message } from 'interfaces';
import { toggleArrayItem } from 'utils/helpers';
import cx from 'classnames';
import messages from 'messages';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { MicrobiologicalLeftRight, MicrobiologicalLocalization, OrderWizardRequirement } from 'interfaces/api';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import { find, groupBy, map, pick, sortBy } from 'lodash';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useEnv } from 'store/components/injectEnv.tsx';
import { useAuthLid } from 'modules/auth/providers';
import { faMessageMedical } from '@fortawesome/pro-solid-svg-icons';

type InfoMessages = {
  title?: Message;
  text?: Message;
  infoBody?: Message;
  infoLabel?: Message;
  options?: Record<string, Message>;
};

type OptionsConfig = {
  labels: InfoMessages;
  image?: HelgaImageType;
  withButtons?: boolean;
};

const optionLabels = messages.orders.requirementOptions;

const configMapping: Record<MissingRequirementOption, OptionsConfig> = {
  [MissingRequirementOption.Form]: {
    labels: optionLabels.form,
    image: HelgaImageType.Report,
  },
  [MissingRequirementOption.Analyses]: {
    labels: optionLabels.analyses,
    image: HelgaImageType.MagnifierBacterium,
    withButtons: true,
  },
  [MissingRequirementOption.LeftRight]: {
    labels: optionLabels.leftRight,
    image: HelgaImageType.SelectAbc,
  },
  [MissingRequirementOption.Intraoperative]: {
    labels: optionLabels.intraoperative,
    image: HelgaImageType.SelectAbc,
  },
  [MissingRequirementOption.Origin]: {
    labels: optionLabels.origin,
    image: HelgaImageType.SelectAbc,
    withButtons: true,
  },
  [MissingRequirementOption.Localization]: {
    labels: optionLabels.localizations,
    withButtons: true,
  },
  [MissingRequirementOption.DynamicMaterials]: {
    labels: optionLabels.dynamicMaterials,
    image: HelgaImageType.MagnifierBacterium,
    withButtons: true,
  },
};

export const RequirementOptions = () => {

  const lid = useAuthLid();
  const { translate } = useIntl();
  const { BACKEND_URL } = useEnv();

  const { wizardSettings: { forms, preferences } } = useOfficeDoctorContext();
  const { pending, setPending } = useBasketContext();
  const upsertBasket = useUpsertBasket();
  const filterForms = useRequirementFilteredForms();

  const setPendingRequirementProperties = useCallback((properties: Partial<OrderWizardRequirement>) => {
    setPending({ ...pending, requirement: { ...pending.requirement, ...properties } });
  }, [pending, setPending]);

  const toggleAnalysis = useCallback((analysis: string) => {
    setPendingRequirementProperties({ selectedAnalyses: toggleArrayItem(pending.requirement.selectedAnalyses, analysis) });
  }, [pending, setPendingRequirementProperties]);

  const analysisSelected = useCallback((analysis: string) => {
    return pending.requirement?.selectedAnalyses?.includes(analysis);
  }, [pending?.requirement?.selectedAnalyses]);

  if (!pending) {
    return null;
  }

  const { labels, image, withButtons } = configMapping[pending.missing];
  const { title, text, infoBody, infoLabel } = labels;

  const helgaProps: HelgaContainerProps = {
    title,
    text,
    infoBody,
    infoLabel,
    image: preferences.orderWizardDisableHelgaImage ? undefined : image,
  };

  let disabledButton = false;

  if (pending.missing === MissingRequirementOption.Analyses && !pending.requirement.selectedAnalyses?.length) {
    disabledButton = true;
  } else if (pending.missing === MissingRequirementOption.Origin && !pending.requirement.origin?.length) {
    disabledButton = true;
  } else if (pending.missing === MissingRequirementOption.Localization && !pending.requirement.selectedLocalizations?.length) {
    disabledButton = true;
  } else if (pending.missing === MissingRequirementOption.DynamicMaterials && !pending.requirement.dynamicMaterials?.filter(m => !!m.text).length) {
    disabledButton = true;
  }

  const localizationsLevel1 = pending.requirement.localizations.filter(f => f.level === 1);
  const localizationsLevel2 = pending.requirement.localizations.filter(f => f.level === 2);
  const localizationsLevel3 = pending.requirement.localizations.filter(f => f.level === 3);

  const renderLocalizationButton = (localization: MicrobiologicalLocalization) => (
    <li key={localization.lokid}>
      <Button
        onClick={() => setPendingRequirementProperties({
          selectedLocalizations: [
            ...(pending.requirement.selectedLocalizations || []).filter(l => find(pending.requirement.localizations, { lokid: l.lokid }).level !== localization.level),
            pick(localization, ['lokid', 'kuerzel', 'name1', 'level']),
          ],
        })}
        className={cx({ 'button-selected': (pending.requirement.selectedLocalizations || []).map(l => l.lokid).includes(localization.lokid) })}
      >
        {localization.name1}
      </Button>
    </li>
  );

  return (

    <HelgaContainer
      {...helgaProps}
      buttons={withButtons
        ? [(
          <Control
            label={messages.general.cancel}
            onClick={() => setPending(undefined)}
            button={{}}
          />
        ), (
          <Control
            icon={faArrowRight}
            label={messages.general.next}
            onClick={() => upsertBasket({ ...pending.requirement })}
            button={{
              disabled: disabledButton,
              type: 'primary',
            }}
          />
        )]
        : undefined}
    >

      {pending.missing === MissingRequirementOption.Form && (
        <ul>
          {filterForms(pending.requirement).map((id) => {

            const form = find(forms, { id });
            const { costUnit, name } = form;
            const formattedFormCostUnitFormat = translate(optionLabels.formCostUnitFormat, { costUnit, name }) ?? '';

            return form && (
              <li key={form.id}>
                <Button onClick={() => upsertBasket({ ...pending.requirement, formId: form.id })}>
                  {formattedFormCostUnitFormat}
                </Button>
              </li>
            );
          })}
        </ul>
      )}

      {pending.missing === MissingRequirementOption.Analyses && (
        <ul className={preferences.orderWizardBakModalBlockButtons ? 'block-buttons' : ''}>
          {(pending.requirement.analyses || []).map((analysis) => {

            const button = (
              <Control
                button={{}}
                label={analysis.longName}
                onClick={() => toggleAnalysis(analysis.shortName)}
                iconBadge={analysis.hint?.length > 0
                  ? { icon: faMessageMedical, color: Color.Blue }
                  : undefined}
                className={cx({ 'button-selected': analysisSelected(analysis.shortName) })}
              />
            );

            return (
              <li key={analysis.shortName}>
                {
                  analysis.hint?.length > 0
                    ? (
                      <Tooltip placement={'topRight'} title={<span dangerouslySetInnerHTML={{ __html: analysis.hint }}/>}>
                        <div>
                          {button}
                        </div>
                      </Tooltip>
                    )
                    : button
                }
              </li>
            );
          })}
        </ul>
      )}

      {pending.missing === MissingRequirementOption.DynamicMaterials && (
        <div className={'content'}>

          <List
            className={cx('oa-wizard-list dynamic-materials-sample-list')}
            items={pending.requirement.materials.map(m => ({
              id: m.id,
              title: m.name,
              subtitle: (<span><Translate message={messages.orders.sampleType}/> {m.sampleType}</span>),
              images: [`${BACKEND_URL}/api/orders/samples/${m.sampleId}/image/small?lid=${lid}`],
            }))}
          />

          <Form
            initialValue={{ dynamicMaterials: pending.requirement.dynamicMaterials || [{ text: '' }] }}
            suppressControls
            onChange={({ dynamicMaterials }) => {
              setPendingRequirementProperties({ dynamicMaterials: dynamicMaterials.filter(m => !!m.text) });
            }}
          >
            {({ DynamicFields }) => (
              <DynamicFields
                addLabel={optionLabels.dynamicMaterials.add}
                property={'dynamicMaterials'}
                horizontal
                sortable
              >
                {({ Input, index }) => (
                  <Input
                    autoFocus
                    floating
                    property={'text'}
                    label={translate(optionLabels.dynamicMaterials.sample, { number: index + 1 })}
                  />
                )}
              </DynamicFields>
            )}

          </Form>
        </div>
      )}

      {pending.missing === MissingRequirementOption.LeftRight && (
        <ul>
          {[MicrobiologicalLeftRight.Left, MicrobiologicalLeftRight.Right].map(leftRight => (
            <li key={leftRight}>
              <Button onClick={() => upsertBasket({ ...pending.requirement, leftRight })} className={cx({ 'button-selected': pending.requirement.leftRight === leftRight })}>
                <Translate message={labels.options[leftRight]}/>
              </Button>
            </li>
          ))}
        </ul>
      )}

      {pending.missing === MissingRequirementOption.Intraoperative && (
        <ul>
          {['no', 'yes'].map((value, intraoperative) => (
            <li key={intraoperative}>
              <Button
                onClick={() => upsertBasket({ ...pending.requirement, intraoperative: !!intraoperative })}
                className={cx({ 'button-selected': pending.requirement.intraoperative === !!intraoperative })}
              >
                <Translate message={labels.options[value]}/>
              </Button>
            </li>
          ))}
        </ul>
      )}

      {pending.missing === MissingRequirementOption.Origin && (
        <div className={'content'}>
          <Input.TextArea
            value={pending.requirement.origin}
            onChange={(e) => {
              setPendingRequirementProperties({ origin: e.target.value });
            }}
          />
        </div>
      )}

      {pending.missing === MissingRequirementOption.Localization && (
        <div className={'localizations'}>
          <>
            {localizationsLevel1.length > 0 && (
              <>
                <h2>Lokalisationsebene 1</h2>
                <ul className={preferences.orderWizardBakModalBlockButtons ? 'block-buttons' : ''}>
                  {sortBy(localizationsLevel1, l => l.sort).map(renderLocalizationButton)}
                </ul>
              </>
            )}
            {localizationsLevel2.length > 0 && (
              <>
                <h2>Lokalisationsebene 2</h2>
                <ul className={preferences.orderWizardBakModalBlockButtons ? 'block-buttons' : ''}>
                  {sortBy(localizationsLevel2, l => l.sort).map(renderLocalizationButton)}
                </ul>
              </>
            )}
            {localizationsLevel3.length > 0 && (
              <>
                <Tabs
                  tabs={map(groupBy(sortBy(localizationsLevel3, l => l.sort), l => l.bereich), (items, title) => ({
                    title,
                    children: () => (
                      <ul className={preferences.orderWizardBakModalBlockButtons ? 'block-buttons' : ''}>
                        {sortBy(items, l => l.sort).map(renderLocalizationButton)}
                      </ul>
                    ),
                  }))}
                />
              </>
            )}
          </>
        </div>
      )}

    </HelgaContainer>
  );
};
