import React, { useCallback } from 'react';
import { Color } from 'interfaces';
import {
  AggregatedProfileProperties,
  AggregatedSuperRequirementProperties,
  BookmarkedMode,
  CompletionListOrderBy,
  ComposedOrderProperties,
  Feature,
  Order,
  OrderDetailDefaultGroupBy,
  OrderDetailDefaultSortBy,
  OrderDigitalSignature,
  OrderForm,
  OrderListMode,
  OrderPatientGDTSharedProperties,
  OrderPatientMetaPropertiesSchema,
  OrderRequirement,
  OrderRequirementSchema,
  OrderSample,
  OrderWizardLocalization,
  OrderWizardRequirement,
  RequirementAggregationType,
  RequirementType,
  UserType,
  WriteableOrderProperties,
  WriteableOrderPropertiesSchema,
} from 'interfaces/api';
import { arrayify } from 'utils/helpers';
import { BadgeType, Container, IconProps, List, ListItem } from 'components';
import { Translate, useIntl } from 'providers';
import messages from 'messages';
import { faClipboardMedical, faCommentAlt, faCommentAltCheck, faCommentAltExclamation, faFileCheck, faVial as faVialLight } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  faArchive,
  faBallPile,
  faBug,
  faCalendar,
  faFileAlt,
  faFileSignature as faFileSignatureRegular,
  faFont,
  faPaperPlane,
  faShoppingCart,
  faStethoscope,
  faSyringe,
  faTag,
  faTags as faTagsRegular,
  faTimesCircle,
  faTruckMedical,
  faVial,
} from '@fortawesome/pro-regular-svg-icons';
import { ListLayoutMode, useGuard } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { filter, find, findKey, flatten, get, groupBy, isEmpty, isNull, keys, map, mapValues, omitBy, pick, sortBy, sum, sumBy, uniqBy, values } from 'lodash';
import { freeTextByType, getBadgeForRequirement, isProfile, isSuperRequirement, useInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';
import { useEnv } from 'store/components/injectEnv';
import { useAuthLid } from 'modules/auth/providers';
import { OrderAggregationConfig, OrderStatusColor } from 'modules/orders/interfaces';
import dayjs from 'dayjs';
import { FormatPrice } from 'providers/IntlProvider/FormatPrice';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { useAsync } from 'react-use';
import axios from 'axios';

export const OrderAggregations: OrderAggregationConfig[] = [{
  type: RequirementAggregationType.Category,
  color: Color.Turquoise,
  icon: faTag,
}, {
  type: RequirementAggregationType.Indication,
  color: Color.SkyBlue,
  icon: faStethoscope,
  guard: { feature: Feature.IndicationSearch },
}, {
  type: RequirementAggregationType.OrderForm,
  color: Color.Yellow,
  icon: faFileAlt,
}, {
  type: RequirementAggregationType.Materials,
  color: Color.Salmon,
  icon: faVial,
}, {
  type: RequirementAggregationType.Analysis,
  color: Color.Bak,
  icon: faBug,
  className: 'bak-aggregation',
  guard: { feature: Feature.BakModule },
}, {
  type: RequirementAggregationType.Name,
  color: Color.Beige,
  icon: faFont,
}];

const orderModeLabels = messages.orders.modes;

export const OrderModes: ListLayoutMode<OrdersListContext>[] = [{
  type: undefined,
  title: messages.orders.mode.home,
  color: Color.Blue,
  icon: faTagsRegular,
}, {
  type: OrderListMode.Bookmarked,
  title: orderModeLabels[OrderListMode.Bookmarked],
  color: Color.Turquoise,
  icon: faCalendar,
  onSelect: ({ context }) => ({ filters: { bookmarkedMode: context.defaultBookmarkStatus || BookmarkedMode.Today } }),
}, {
  type: OrderListMode.Completion,
  title: orderModeLabels[OrderListMode.Completion],
  color: Color.SkyBlue,
  icon: faPaperPlane,
  guard: { feature: Feature.ClosureList },
  onSelect: ({ context }) => ({ filters: { completionOrder: context.defaultCompletionOrderBy || CompletionListOrderBy.OrderDate } }),
}, {
  type: OrderListMode.Transport,
  title: orderModeLabels[OrderListMode.Transport],
  color: Color.Yellow,
  icon: faTruckMedical,
  guard: { feature: Feature.TransportList },
  margin: 2,
  onSelect: () => ({
    transportationHistoryDate: dayjs(),
    transportationHistoryId: undefined,
  }),
}, {
  type: OrderListMode.Journal,
  title: orderModeLabels[OrderListMode.Journal],
  color: Color.Salmon,
  icon: faArchive,
  guard: { feature: Feature.Journal },
}, {
  type: OrderListMode.DigitalSignature,
  title: orderModeLabels[OrderListMode.DigitalSignature],
  color: Color.GreenSpring,
  icon: faFileSignatureRegular,
  guard: { feature: Feature.DigitalSignatureModule },
  margin: 3,
  onSelect: () => ({ filters: { digitalSignature: OrderDigitalSignature.Unsigned } }),
}, {
  type: OrderListMode.RealReRequest,
  title: orderModeLabels[OrderListMode.RealReRequest],
  color: Color.Beige,
  icon: faShoppingCart,
  guard: { feature: Feature.RealRerequest, forbidden: [UserType.ARZ, UserType.PAT] },
}, {
  type: OrderListMode.MassOrders,
  title: orderModeLabels[OrderListMode.MassOrders],
  color: Color.Bone,
  icon: faBallPile,
  guard: { feature: Feature.MassOrders, forbidden: [UserType.ARZ] },
}];

export const getListItemFlag = (entity: ComposedOrderProperties | Order) => {
  return flatten(arrayify(entity.status).map(s => OrderStatusColor[s]));
};

export const isComposedOrder = (entity: ComposedOrderProperties | Order): entity is ComposedOrderProperties => {
  return (entity as ComposedOrderProperties)?.orderCount !== undefined;
};

export const getOrderTransportationSummary = (orders: ListItem[]) => {
  const samples = mapValues(groupBy(flatten(orders.map(o => o.meta.materials as OrderSample[])), 'name'), s => sumBy(s, 'count'));
  const total = sum(values(samples));
  return { samples, total };
};

export const mapCostUnit = (costUnit: string) => ({
  P: 'Privat',
  PRIVAT: 'Privat',
  K: 'Kasse',
  KASSA: 'Kasse',
  KASSE: 'Kasse',
  I: 'Intern',
  INTERN: 'Intern',
})[costUnit?.toUpperCase()] || costUnit;

export const getPatientTitle = (title: string) => !isEmpty(title)
  ? title
  : (
    <span className={'empty-patient-name'}>
      <Translate message={messages.general.noPatientSelected}/>
    </span>
  );

export const getOrderDoctorDisplayName = (entity: ComposedOrderProperties | Order, showRoomNumber: boolean) => {
  let displayString = entity.selectedDoctor || (entity.doctor && entity.doctor.displayName);
  const composedOrder = isComposedOrder(entity) && !isEmpty(entity.hospitalRoomNumber);
  const noComposedOrder = !isComposedOrder(entity) && !isEmpty(entity.patient?.hospitalRoomNumber);
  const isKis = entity.doctor && entity?.doctor.localisation === OrderWizardLocalization.KIS || entity.doctor?.laboratoryLocalisation === OrderWizardLocalization.KIS;
  if (showRoomNumber && isKis) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.hospitalRoomNumber);
    }
  } else if (showRoomNumber) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.hospitalRoomNumber);
    }
  }
  return displayString;
};

const createStationAndRoomNumberDisplayString = (station: string, roomNumber: string): string => {
  return [station, roomNumber].filter(a => a && a.trim().length > 0).join(', ');
};

export const getCurrencyAsciiSign = (localization?: OrderWizardLocalization) => {
  if (localization === OrderWizardLocalization.CHE) {
    return <>CHF&nbsp;</>;
  }
  return <>&euro;</>;
};

const getRequirementBasePrice = (requirement: OrderRequirement, form?: OrderForm) => parseFloat(
  (find(requirement.formLevelPrices, p => p.formId === (form?.id || requirement.formId))?.price || requirement.price) + '',
);

export const getRequirementPrice = (requirement: OrderRequirement, form?: OrderForm, localization?: OrderWizardLocalization) => {

  if (form?.isPrivate && requirement.isCharged) {
    const price = getRequirementBasePrice(requirement, form);
    if (price > 0) {
      return <>{getCurrencyAsciiSign(localization)}{<FormatPrice price={price}/>}</>;
    } else {
      return <Translate message={messages.orders.wizard.priceOnApplication}/>;
    }
  }

  return null;
};

const getRequirementCostsWithFormFactor = (requirements: OrderRequirement[]) => {
  return sumBy(requirements, requirement => getRequirementBasePrice(requirement) * (parseFloat((requirement.form?.priceFactor || '1') + '') || 1));
};

export const useGetTotalPriceLabel = () => {

  const { translate } = useIntl();
  const { currentOrder } = useOrdersContext();
  const { wizardSettings } = useOfficeDoctorContext();
  const { getSelectedLabel } = useInvoiceTo();

  return useCallback((requirements: OrderRequirement[], localization?: OrderWizardLocalization) => {

    const privateRequirements = requirements.filter(r => find(wizardSettings?.forms, { id: r.formId })?.isPrivate && r.isCharged);

    if (!privateRequirements.length) {
      return null;
    }

    const formBaseCosts = sumBy(uniqBy(map(privateRequirements, r => r.form), 'id'), f => parseFloat((f?.basePrice || '0') + ''));
    const formRequirementCosts = getRequirementCostsWithFormFactor(privateRequirements);
    const totalCosts = formRequirementCosts + formBaseCosts;

    const hasMicrobiological = requirements.filter(requirement => requirement.entityType === RequirementType.Microbiological).length > 0;
    const hasUndefinedCosts = privateRequirements.filter(requirement => !(getRequirementBasePrice(requirement) > 0)).length > 0;

    const groupedByInvoiceTo = groupBy(privateRequirements, r => r.invoiceTo || r.form?.defaultInvoiceTo || currentOrder?.patient?.invoiceTo);

    return (
      <>
        <Container className={'total-price'}>
          <span className={'total-price-label'} dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.costs) }}/>
          {hasMicrobiological || hasUndefinedCosts
            ? <Translate message={messages.orders.wizard.priceOnApplication}/>
            : <>{getCurrencyAsciiSign(localization)}<FormatPrice price={totalCosts}/></>
          }
        </Container>
        {wizardSettings?.preferences?.orderWizardSeparateInvoiceTo && (
          <>
            {map(groupedByInvoiceTo, (requirements, invoiceTo) => (
              <Container className={'total-price total-price-sub'}>
                <span className={'total-price-label'}>
                  <Translate message={messages.orders.wizard.costSeparation} values={{ type: getSelectedLabel(invoiceTo) }}/>
                </span>
                <>{getCurrencyAsciiSign(localization)}<FormatPrice price={getRequirementCostsWithFormFactor(requirements)}/></>
              </Container>
            ))}
            <Container className={'total-price total-price-sub'}>
              <span className={'total-price-label'}>
                <Translate message={messages.orders.wizard.costSeparation} values={{ type: translate(messages.orders.orderForm) }}/>
              </span>
              <>{getCurrencyAsciiSign(localization)}<FormatPrice price={formBaseCosts}/></>
            </Container>
          </>
        )}
      </>
    );
  }, [translate, wizardSettings]);

};

export enum OrderListRequirementsGroupBy {
  Form = 'form.name',
  Material = 'materials[0].name',
}

const OrderDetailDefaultGroupByMapping: Record<OrderDetailDefaultGroupBy, string> = {
  [OrderDetailDefaultGroupBy.Forms]: 'form.name',
  [OrderDetailDefaultGroupBy.Materials]: 'materials[0].name',
};

export const useOrderListRequirements = () => {

  const { BACKEND_URL } = useEnv();

  const { translate } = useIntl();
  const lid = useAuthLid();
  const guard = useGuard();

  return useCallback((
    order: Order,
    groupBy: OrderDetailDefaultGroupBy = OrderDetailDefaultGroupBy.Forms,
    sort: OrderDetailDefaultSortBy = OrderDetailDefaultSortBy.Order,
  ): ListItem<OrderRequirement>[] => {

    const items = order.requirements?.reduce((items, requirement) => {

      const { id, longName, shortName, leftRight, origin, analyses, selectedAnalyses } = requirement;

      const item: ListItem = { id };

      item.images = filter((requirement.materials || []).map((material) => {
        return `${BACKEND_URL}/api/orders/samples/${material.sampleId}/image/small?lid=${lid}`;
      }));

      item.title = longName;
      if (requirement.entityType === RequirementType.Microbiological) {
        item.title += ' (' + filter([
          shortName,
          !!leftRight && translate(messages.orders.requirementOptions.leftRight.options[leftRight]),
          origin,
        ]).join(', ') + ')';
      }

      item.icons = [];

      if (requirement.ruleInfoText) {
        item.icons.push(faFileCheck);
      }

      if (requirement.hint) {
        item.icons.push(faClipboardMedical);
      }

      if (requirement.reRequested) {
        item.icons.push({
          icon: [{ icon: faVialLight }, { icon: faPlusCircle, transform: 'shrink-7 down-4 right-3.5' }],
          tooltip: messages.orders.wizard.realReRequest.tooltip,
        });
      }

      if (requirement.cancelled_at) {
        item.icons.push({
          icon: faTimesCircle, color: Color.Red,
          tooltip: messages.orders.controls.cancelRequirement.isCancelled,
        });
      }

      guard({ feature: Feature.RequirementFreeText }, () => {
        if (requirement.freeTextAllowed) {
          item.icons.push(freeTextByType(requirement) ? faCommentAltCheck : (requirement.freeTextMandatory ? { icon: faCommentAltExclamation, color: Color.Red } : faCommentAlt));
        }
      });

      item.subtitle = requirement.entityType === RequirementType.Clinical ? shortName : selectedAnalyses.map(a => find(analyses, { shortName: a })?.longName).join(', ');

      if (requirement.selectedLocalizations?.length > 0) {
        item.subtitle += ' - ' + requirement.selectedLocalizations.map(l => l.name1).join(', ');
      }

      if (requirement.form.isDynamicMaterial && requirement.dynamicMaterials?.length > 0) {
        item.body = (
          <List
            className={'dynamic-materials'}
            items={requirement.dynamicMaterials.map((material, idx) => ({ id: idx, title: `${material.text}` }))}
          />
        );
      }

      item.faded = !!requirement.cancelled_at;
      item.meta = requirement;

      item.badge = getBadgeForRequirement(requirement);
      item.badgeType = BadgeType.Inline;

      item.groupByValue = get(requirement, OrderDetailDefaultGroupByMapping[groupBy]);

      if (groupBy === OrderDetailDefaultGroupBy.Materials) {
        return [...items, ...requirement.materials.map(m => ({ ...item, groupByValue: m.name }))];
      }

      return [...items, item];

    }, [] as ListItem[]);

    return sortBy(items, (item, idx) => sort === OrderDetailDefaultSortBy.Name ? item.groupByValue + '-' + item.title : idx);

  }, []);

};

export const getWriteableOrder = (order: WriteableOrderProperties, filterRequirements?: boolean) => omitBy({
  ...pick(omitBy(order, isNull), keys(WriteableOrderPropertiesSchema)),
  patient: pick(omitBy(order.patient, isNull), keys(OrderPatientMetaPropertiesSchema)),
  covid: pick(omitBy(order.covid, isNull), keys()),
  requirements: (order.requirements || [])
    .map(r => pick(omitBy(r, isNull), keys(OrderRequirementSchema)))
    .filter(r => !filterRequirements || r.profileId || r.form?.costUnit === order.costUnit),
}, isNull);

export const getRequirementId = (requirement: OrderWizardRequirement | AggregatedProfileProperties | AggregatedSuperRequirementProperties) => {
  return requirement.id + `:${JSON.stringify(requirement.flags)}:${JSON.stringify(requirement.filter)}:${JSON.stringify((requirement as any).selectedAnalyses)}`;
};

export const getRequirementShortName = (requirement: OrderWizardRequirement | AggregatedProfileProperties) => {
  return isProfile(requirement) || isSuperRequirement(requirement)
    ? requirement.shortName
    : requirement.shortName + (requirement.laboratoryGroup !== 'LA' ? ` (${requirement.laboratoryGroup})` : '');

};

export const SampleInLaboratoryIcon: IconProps = {
  icon: [{ icon: faSyringe }, { icon: faUserCircle, transform: 'shrink-7 down-4 right-3.5' }],
  tooltip: messages.orders.filters.sampleInLabFilter.label,
};

export const isSingleOrderView = (context: OrdersListContext) => {
  return context.mode !== OrderListMode.Bookmarked;
};

export const sortOrders = <T extends Order | WriteableOrderProperties>(orders: T[], sortByName: boolean) => sortBy(orders.map((order, idx) => {

  const { displayName, lastName, firstName } = order.patient || {};

  const sort = sortByName
    ? displayName || (lastName?.toLowerCase() + ' ' + firstName?.toLowerCase())
    : idx;

  return { order, idx, sort };

}), 'sort');

type SwitzerlandBagsConfig = { chGlns?: Record<string, string>; chBags?: Record<string, string> };

export const useSwitzerlandBagsPromise = new Promise<SwitzerlandBagsConfig>(async (resolve, reject) => {
  const chBags = (await axios.get('/ch_bag_de.json', { responseType: 'json' })).data;
  const chGlns = (await axios.get('/ch_bag_to_gln.json', { responseType: 'json' })).data;
  resolve({ chBags, chGlns });
});

/**
 *
 */
export const useSwitzerlandBags = () => {

  const guard = useGuard();
  const featureEnabled = guard({ feature: Feature.SwitzerlandInsuranceMapping }, () => true);

  const switzerlandBagsConfig = useAsync(async (): Promise<SwitzerlandBagsConfig> => {
    return featureEnabled ? useSwitzerlandBagsPromise : Promise.resolve({});
  });

  const mapSwitzerlandInsuranceValues = useCallback((data: OrderPatientGDTSharedProperties, bagsConfig?: SwitzerlandBagsConfig): OrderPatientGDTSharedProperties => {

    if (!switzerlandBagsConfig.value && !bagsConfig) {
      return data;
    }

    const { chGlns, chBags } = bagsConfig ? bagsConfig : switzerlandBagsConfig.value;

    if (data.globalLocationNumber?.length) {
      data.bag = findKey(chGlns, g => g === data.globalLocationNumber);
      data.insuranceName = get(chBags, data.bag);
    } else if (data.bag?.length) {
      data.insuranceName = get(chBags, data.bag);
      data.globalLocationNumber = get(chGlns, data.bag);
    } else if (data.insuranceName?.length) {
      data.bag = findKey(chBags, b => b === data.insuranceName);
      data.globalLocationNumber = get(chGlns, data.bag);
    }

    return data;

  }, [featureEnabled, switzerlandBagsConfig.value]);

  return {
    switzerlandBagsConfig,
    mapSwitzerlandInsuranceValues,
  };

};

/**
 *
 */
export const usePatientInvoiceToOptions = (hideInsurance: boolean) => {
  return filter([
    { value: 'Auftraggeber', label: messages.orders.additionalFields.invoiceToOptions.purchaser },
    { value: 'Patient', label: messages.orders.additionalFields.invoiceToOptions.patient },
    !hideInsurance ? { value: 'Versicherung', label: messages.orders.additionalFields.invoiceToOptions.insurance } : undefined,
    { value: 'Andere', label: messages.orders.additionalFields.invoiceToOptions.other },
  ]);
};
